<template>
  <div class="announcementDetail merch-card" v-loading="loading">
    <div class="edit-title">编辑公告</div>
    <div class="row-info">
        <div><span>*</span>公告标题：</div>
        <div>{{announcementInfo.title}}</div>
    </div>
    <div class="row-info">
        <div><span>*</span>发布对象：</div>
        <div>{{announcementInfo.qualifyTypeNames}}</div>
    </div>
    <div class="row-info">
        <div><span>*</span>展示地区：</div>
        <div v-if="announcementInfo.restrictedDistrictNames == '全部地区'">{{announcementInfo.restrictedDistrictNames}}</div>
        <div v-else>自定义</div>
    </div>
    <div class="marg-top"></div>
    <div class="merch-table scorr-roll" :style="`max-height: ` + mainHright + `px`">
        <div class="row-info">
            <div>正文：</div>
            <div class="html-box" v-html="announcementInfo.content"></div>
        </div>
    </div>
    <div class="btn-boxs">
        <div class="btn-2" @click="$router.go(-1)">返回</div>
        <div class="refuse" @click="handleBack" v-if="announcementInfo.status*1 == 1">驳回</div>
        <div class="pass" @click="handlePass" v-if="announcementInfo.status*1 == 1">通过</div>
    </div>
    <el-dialog title="审核驳回提醒" :visible.sync="backDialog" width="30%">
        <div style="margin-bottom:15px">确定驳回当前【公告发布】审核？如果当前公告不符合平台发布要求，请在驳回原因中注明。</div>
        <el-form ref="causeForm" :model="causeForm" :rules="rules" label-width="96px">
            <el-form-item label="驳回原因：" prop="reason">
                <el-input v-model="causeForm.reason" type="textarea" placeholder="例：图片与公告内容不符" maxlength="51" ></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button @click="backDialog = false">取 消</el-button>
            <el-button type="primary" @click="handleSave" :loading="buttonLoading">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const { mapState, mapActions } = createNamespacedHelpers("Audit"); //vuex公共库
export default {
    data(){
        return {
            loading:false,
            backDialog:false,
            buttonLoading:false,
            causeForm:{
                id:this.$route.query.id,
                type:null,
                reason:'',
            },
            announcementInfo:{},
            rules:{
                reason: [
                    { required: true, message: '驳回原因必填', trigger: 'change' },
                    { min: 0, max: 51, message: '长度在0到50个字符', trigger: 'change' }
                ],
            },
            
        }
    },
    mounted(){
        this.getAnnouncemenDetail()
    },
    methods:{
        ...commonIndex.mapState(["mainHright"]),
        ...mapActions(["getAfficheDetail","postAfficheAudit"]),
        async getAnnouncemenDetail(){
            let id = this.$route.query.id
            let res = await this.getAfficheDetail({id: id})
            this.announcementInfo = res.data
        },
        handleBack(){
            if (this.$refs.causeForm !== undefined) this.$refs.causeForm.resetFields()
            this.backDialog = true 
        },
        handlePass(){
            this.causeForm.type = 1
            this.postAfficheAuditApi()
        },
        async postAfficheAuditApi(){
            this.loading = true
            let res = await this.postAfficheAudit(this.causeForm)
            this.loading = false
            if(res.code == 200){
                this.$message({
                            message: res.message,
                            type: "success",
                        })
            }
            this.getAnnouncemenDetail()
        },
        handleSave(){
            this.$refs.causeForm.validate(async (valid) =>{
                if(valid){
                    this.causeForm.type = 2
                    this.postAfficheAuditApi()
                    this.backDialog = false;
                    
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
    
.announcementDetail{
    .edit-title{
        font-size: 15px;
        font-weight: bold;
        margin: 20px 30px;
    }
    .row-info{
        font-size: 15px;
        font-weight: 400;
        margin: 20px 30px;
        color: #7F7F7F;
        display: flex;
        > div:nth-of-type(1){
            width: 83px;
            text-align: right;
            // white-space: nowrap;
            margin: 0px 10px 0px 5px;
            > span{
                color: red;
            }
        }
        .html-box{
            max-width: 1500px;
        }
 
        .html-box ::v-deep * {
            word-break: break-word;
            max-width: 100% !important;
            width: auto !important;
        }
        .html-box ::v-deep img {
            width: auto !important;
            height: auto !important;
            max-width: 100% !important;
            max-height: 100% !important;
        }
        [alt] {
            max-width: 100%;
            height: auto;
        }
    }
    .marg-top{
        margin-top: 60px;
        height: 1px;
        background-image: linear-gradient(to right, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0.2) 50%);
        background-size: 8px 1px;
        background-repeat: repeat-x;
        margin-bottom: 20px;
    }
    .merch-table {
      margin-top: 1%;
      width: 100%;
      height: 66%;
    }
    .btn-boxs {
            margin-top: 1%;
            width: 100%;
            height: 40px;
            display: flex;
            align-content: center;
            justify-content: center;
            
        div{
            width: 90px;
            height: 36px;
            line-height: 36px;
            font-size: 14px;
            text-align: center;
            border-radius: 4px;
            font-weight: normal;
        } 
        .btn-2{
                background-color: #fff;
                color: #1C1F23 ;
                border: 1px solid #D4D7D9;
                cursor: pointer;
            }
        .pass {
            cursor: pointer;
            margin-left: 15px;
            background: #06B7AE;
            color: #ffffff;
        }
        .refuse {
            margin-left: 15px;
            cursor: pointer;
            background: #F2F2F2;
            color: #999999;
        }
  }
}
</style>